<template>
  <div class="content">
    <p>Thanks for choosing fixed.net.</p>
    <p v-if="result.order">
      Your order reference is <strong>#{{ result.order.orderNum }}</strong
      >.
    </p>
    <p>
      Your website <strong>{{ result.siteUrl.fqdn }}</strong> has been
      successfully added, and our team of experts are onhand and ready to assist
      you.
    </p>
    <p v-if="result.taskId">
      Your new task has also been created, and our agents are already looking
      into it; you can view all progress and update the task thread
      <router-link :to="{ path: `/tasks/${result.taskId}` }">here</router-link>.
    </p>
    <p>Now your site and account is all setup, you may want to:</p>
    <ul>
      <li v-if="!result.taskId">
        <router-link
          :to="{ path: `/sites/${result.siteId}/tasks` }"
          class="has-text-dark"
          ><u>Create your first task</u></router-link
        >
        for our team to assist with
      </li>
      <li>
        Securely
        <router-link
          :to="{ path: `/sites/${result.siteId}/info` }"
          class="has-text-dark"
          ><u>add site information</u></router-link
        >
        – such as notes and logins
      </li>
      <li v-if="result.taskId">
        <router-link
          :to="{ path: `/sites/${result.siteId}/tasks` }"
          class="has-text-dark"
          ><u>Create additional tasks</u></router-link
        >
        for our team to assist with
      </li>
      <li>
        <router-link
          :to="{ path: `/sites/${result.siteId}/backups` }"
          class="has-text-dark"
          ><u>Configure backups</u></router-link
        >
        for {{ result.siteUrl.fqdn }}
      </li>
      <li>
        <router-link :to="{ path: `/account/profile` }" class="has-text-dark"
          ><u>View and manage</u></router-link
        >
        your account
      </li>
    </ul>
    <router-link
      :to="{ path: `/sites/${result.siteId}` }"
      class="button is-success has-margin-top-50"
    >
      <strong>Go to my site</strong>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ConfirmationStep",
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      result: null
    };
  },
  created() {
    this.result = this.$_.cloneDeep(this.payload);
  },
  beforeDestroy() {
    this.$emit("reset");
  }
};
</script>
